import React from 'react';
import { Grid } from '@mui/material';

import Menu from '../menu/Menu';

import logo from '../../icons/Logo.svg';

import styles from '../menu/Menu.module.scss';

const Navbar = () => {
  return (
    <Grid item className={styles['navbar']}>
      <img src={logo} className={styles['logo']} alt="logo" />
      <Menu />
    </Grid>
  );
};

export default Navbar;
